*,
*:before,
*:after {
    box-sizing: border-box;
}

button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;

    background: #283593;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
    color: #DFDEDF;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

button:focus {
    box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
}

button:hover {
    cursor: pointer;
}

.SignIn {}

.SignIn-info {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.Input-Header-text {
    text-align: left;
    font-size: calc(6px + 1vmin);
    margin-bottom: 12px;
}

.SignIn-link:hover {
    cursor: pointer;
}

.SignIn-error {
    font-style: italic;
    font-size: calc(1vmin);
    margin-bottom: 24px;
}

.SignIn-error-invis {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 24px;
}

.Account {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 3vmin);
    color: black;
    padding: 16px;
    width: 100%;
}

.Account-dropdown {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: calc(10px+2vmin);
    font-family: "Poppins", sans-serif;
}

.Account-dropdown .user {
    position: relative;
    width: 70px;
    height: auto;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.7;
}

.Account-dropdown .menu {
    position: absolute;
    top: 120px;
    right: -10px;
    padding: 10px 10px;
    background: pink;
    width: 300px;
    box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
}

.Account-dropdown .menu.active {
    top: 80px;
    visibility: visible;
    opacity: 1;
}

.Account-dropdown .menu::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 28px;
    width: 20px;
    height: 20px;
    background: pink;
    transform: rotate(45deg);
}

.Account-dropdown .menu h3 {
    color: #555;
}

.Account-dropdown .menu ul li {
    list-style: none;
    padding: 16px 0;
    border-top: 2px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
}

.Account-dropdown .menu ul li img {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: 0.5s;
}

.Account-dropdown .menu ul li:hover img {
    opacity: 1;
}

.Account-dropdown .menu ul li div {
    display: inline-block;
    text-decoration: none;
    color: #555;
    font-weight: 500;
    transition: 0.5s;
    width: 300px;
    margin-left: -120px;
    align-items: left;
}

.Account-dropdown .menu ul li:hover div {
    color: #ff5d94;
    cursor: pointer;
}

.Account-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 3vmin);
    color: white;
}

.Account-info .ac {
    color: white;
}

.Account-info .toggle {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.MuiAutocomplete-root {
    color: white;
}

.copy {
    color: black;
    font-size: calc(8px + 1vmin);
}

.copy-prompt {
    cursor: pointer;
    text-align: right;
}

.copy-prompt-press {
    color: green;
}

.copy-prompt-shown {
    opacity: 1;
    transition: all 2s linear;
}

.copy-prompt-hidden {
    opacity: 0;
    cursor: default;
}

.copy-alert {
    color: greenyellow;
    text-align: right;
}

.copy-alert-shown {
    opacity: 1;
    transition: all 100ms linear;
}

.copy-alert-hidden {
    opacity: 0;
    transition: all 300ms linear;
    cursor: default;
}

.Password-header {
    color: black;
    font-size: calc(8px + 1vmin);
    margin-bottom: 6px;
    font-weight: bold;
}

.Password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
}

.Manual-password-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12px;
}

.Password-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}